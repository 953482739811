import axios from 'axios';
import store from '@/store';
import { MessageBox, Message } from 'element-ui';
import router from '../router';
import { getToken } from '@/api/auth';

// console.log(process.env);
const service = axios.create({
  //baseURL: process.env.VUE_APP_BASE_API,
  timeout: 120000,
  baseURL: "https://35.194.230.94/",
  // timeout: 60000,
});

service.interceptors.request.use(
  (config) => {
    const token = getToken();
    // console.log(token);
    // token && (config.headers["X-Token"] = `${token}`)
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // if (res.code !== 200) {
    //     Message({
    //         message: res.message || "Error",
    //         type: "error",
    //         duration: 5 * 1000,
    //     })
    // } else {
    //     return res
    // }
    return res;
  },
  (error) => {
    const { response } = error;
    store.dispatch('loadingHandler', false);
    if (response) {
      errorHandle(response.status, response.data.error, response);
      return Promise.reject(error);
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    }
  }
);

/* 錯誤捕捉 */
const errorHandle = (status, msg, response) => {
  switch (status) {
    case 400:
      break;

    case 401:
      tokenExpire();
      break;

    case 403:
      break;

    case 404:
      router.replace({
        name: '404',
      });
      break;

    case 500:
      break;

    default:
      break;
  }
};

const tokenExpire = () => {
  Message({
    message: '登入逾時，請重新登入！',
    type: 'error',
    duration: 3000,
  });
  /* 登出api */
  store.dispatch('Logout');
  router.replace({
    name: 'login',
  });
};

export default service;
