import request from '../request';

const api = {
  /* Menu */
  getMenu() {
    return request({
      url: '/bid/aim/system/getmenu',
      method: 'get',
    });
  },
  /* 登入紀錄的下拉選單 */
  getCompanyInfo(data) {
    return request({
      url: '/bid/aim/system/getcompanyinfo',
      method: 'post',
      data,
    });
  },
  /* 新聞網站的下拉選單 */
  getOptions(data) {
    return request({
      url: '/bid/aim/system/getoptions',
      method: 'post',
      data,
    });
  },
  //  /* 操作紀錄 */
  // actionRecord() {
  //     return request({
  //       url: "/bid/aim/record/action",
  //       method: "post",
  //     });
  //   },
};

export default api;
