import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      hideMenu: true,
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
    meta: {
      hideMenu: true,
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      title: "首頁",
      hideMenu: false,
    },
  },
  /* Graph Test */
  {
    path: "/graph",
    name: "Relation Grap",
    component: () => import("../views/graph.vue"),
    meta: {
      title: "圖形測試",
      hideMenu: true,
    },
  },
  /* 警示資訊查詢 */
  {
    path: "/warningInfo",
    name: "warningInfo",
    component: () => import("../views/warningInfo.vue"),
    meta: {
      title: "警示資訊查詢",
      hideMenu: false,
    },
  },
  /*新聞詳細頁*/
  {
    path: "/detailNews",
    name: "detailNews",
    component: () => import("../views/detailNews.vue"),
    meta: {
      title: "新聞詳細頁",
      hideMenu: true,
    },
  },
   /*表格詳細頁*/
   {
    path: "/tableDetailNews",
    name: "tableDetailNews",
    component: () => import("../views/tableDetailNews.vue"),
    meta: {
      title: "聯合國制裁名單詳細頁",
      hideMenu: false,
    },
  },
  /*名單管理(案件管理)*/
  {
    path: "/caseManage",
    name: "caseManage",
    component: () => import("../views/caseManage.vue"),
    meta: {
      title: "名單管理",
      hideMenu: false,
    },
  },
  /*名單管理*/
  {
    path: "/warningListManage",
    name: "warningListManage",
    component: () => import("../views/warningListManage.vue"),
    meta: {
      title: "警示名單查詢",
      hideMenu: false,
    },
  },
  /*管理報表__警示名單彙總表*/
  {
    path: "/warningListExport",
    name: "warningListExport",
    component: () => import("../views/systemManage/warningListExport.vue"),
    meta: {
      title: "警示名單彙總表",
      hideMenu: false,
    },
  },
  /*管理報表__系統登入記錄*/
  {
    path: "/sysLoginLog",
    name: "sysLoginLog",
    component: () => import("../views/systemManage/sysLoginLog.vue"),
    meta: {
      title: "系統登入記錄",
      hideMenu: false,
    },
  },
  /*管理報表__系統操作記錄*/
  {
    path: "/sysOperateLog",
    name: "sysOperateLog",
    component: () => import("../views/systemManage/sysOperateLog.vue"),
    meta: {
      title: "系統操作記錄",
      hideMenu: false,
    },
  },
  /*編輯關聯新聞頁*/
  {
    path: "/relatedNews",
    name: "relatedNews",
    component: () => import("../views/relatedNews.vue"),
    meta: {
      title: "關聯新聞頁",
      hideMenu: true,
    },
  },
  /*新增/編輯/檢視警示名單*/
  {
    path: "/warningListAdd",
    name: "warningListAdd",
    component: () => import("../views/warningList/warningListAdd.vue"),
    meta: {
      title: "檢視警示名單",
      hideMenu: true,
    },
  },
  /*帳號管理頁*/
  {
    path: "/accountManage",
    name: "accountManage",
    component: () => import("../views/accountManage.vue"),
    meta: {
      title: "帳號管理",
      hideMenu: false,
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
