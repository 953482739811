import Vue from "vue";
import Vuex from "vuex";
import { getToken, removeToken, setToken } from "../api/auth";
import API from "../api/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    token: getToken(),
    userInfo: {},
    menuList: null,
    routerFrom:"",
  },
  mutations: {
    SETLOADING(state, payload) {
      state.loading = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_USERINFO(state, payload) {
      state.userInfo = payload;
    },
    SET_MENU(state, payload) {
      state.menuList = payload;
    },
    SET_ROUTERFROM(state, payload){
      state.routerFrom = payload;
    }
  },
  actions: {
    loadingHandler(state, payload) {
      state.commit("SETLOADING", payload);
    },
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        API.user
          .login(userInfo.account, userInfo.password , userInfo.userIP)
          .then((res) => {
            if (!!res) {
              setToken(res.access_token);
              commit("SET_TOKEN", res.access_token);
              window.localStorage.setItem("userID", res.UserID);
              resolve("success");
            } else {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err.response.statusText);
          });
      });
    },
    Logout({ commit }) {
      removeToken();
      commit("SET_TOKEN", "");
    },
    GetInfo({ commit }) {
      //console.log("goinfo打api");
      return new Promise((resolve, reject) => {
        API.user
          .getInfo({
            UserID: window.localStorage.getItem("userID"),
          })
          .then((res) => {
            commit("SET_USERINFO", res.data[0]);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    GetMenu({ commit }) {
      API.system.getMenu().then((res) => {
        const { menu } = res;
        const mainMenu = menu.filter((main) => main.Pid === 0);
        const childrenMenu = menu.filter((children) => children.Pid !== 0);
        const menuList = mainMenu.map((p) => {
          p.children = childrenMenu.filter((c) => c.Pid == p.Id);
          return p;
        });
        commit("SET_MENU", menuList);
      });
    },
    SaveHistory({ commit },routerFrom) {
      commit("SET_ROUTERFROM", routerFrom.name);
    }
  },
  
});

export const getters = {
  userInfo: state => state.userInfo,
}
